<template>
  <div class="border-all rounded">
    <div class="border-top py-3 px-4">
      <div class="d-flex justify-end">
        <v-btn @click="clear" class="mr-3" color="gray lighten-3" depressed>
          Təmizlə
        </v-btn>
        <v-btn @click="search" color="primary" depressed>
          Axtar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filters: {},
    };
  },
  methods: {
    search() {
      this.$emit("filtersChanged", this.filters);
    },
    clear() {
      this.$emit("filtersChanged", {});
      this.filters = {};
    },
  },
};
</script>
