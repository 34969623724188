<template>
  <v-card>
    <v-card-title class="py-3 px-4 border-bottom">
      <span class="gray--text text--darken-3 font-weight-regular">
        {{ isEditMode ? "BRX/AİK-in redaktəsi" : "Yeni BRX/AİK" }}
      </span>
    </v-card-title>
    <v-card-text>
      <v-form
        id="createItemForm"
        ref="form"
        v-model="formValidation"
        lazy-validation
        @submit.prevent="handleFormSubmit"
      >
        <v-row>
          <v-col cols="3">
            <v-text-field
              type="text"
              label="Operator"
              filled
              dense
              v-model="formData.company"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="Qovşaq"
              filled
              dense
              v-model="formData.junction"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="BRX və AİK"
              filled
              dense
              v-model="formData.brxOrAik"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="BRX və AİK No"
              filled
              dense
              v-model="formData.brxOrAikId"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="Göstəriş/No"
              filled
              dense
              required
              v-model="formData.decree"
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="Müqavilə/Əlavəsi"
              filled
              dense
              v-model="formData.contract"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="date"
              label="Müqavilə tarixi"
              filled
              dense
              required
              v-model="formData.contractDate"
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="Xidmət"
              filled
              dense
              required
              v-model="formData.serviceType"
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="Sürət  (Mbit/s)"
              filled
              dense
              required
              v-model="formData.speed"
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="Vlan"
              filled
              dense
              required
              v-model="formData.vlan"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              type="text"
              label="Məntəqə/ünvan"
              filled
              dense
              v-model="formData.sideOrAddress"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="Uplink port"
              filled
              dense
              v-model="formData.uplinkPort"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="Uplink side"
              filled
              dense
              v-model="formData.uplinkSide"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="Son port"
              filled
              dense
              v-model="formData.lastPort"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="Lif"
              filled
              dense
              v-model="formData.lif"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="Son avadanlıq"
              filled
              dense
              v-model="formData.lastEquipment"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              type="text"
              label="Son avadanlığın İP ünvanı"
              filled
              dense
              v-model="formData.lastEquipmentIP"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-textarea
              type="text"
              label="Qeyd"
              filled
              dense
              required
              auto-grow
              rows="1"
              v-model="formData.note"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="py-3 px-4 d-flex justify-end border-top">
      <v-btn color="gray lighten-3" depressed @click="toggleDialog">
        Bağla
      </v-btn>
      <v-btn
        :loading="isSubmittingForm"
        type="submit"
        form="createItemForm"
        color="success"
        depressed
      >
        {{ isEditMode ? "Redaktə et" : "Yarat" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    toggleDialog: {
      type: Function,
      required: true,
    },
    isSubmittingForm: {
      type: Boolean,
      required: true,
    },
    selectedItem: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      formValidation: false,
      formData: {
        company: null,
        junction:null,
        brxOrAik: null,
        brxOrAikId: null,
        decree: null,
        contract: null,
        contractDate: null,
        serviceType: null,
        speed: null,
        vlan: null,
        sideOrAddress: null,
        uplinkPort: null,
        uplinkSide: null,
        lastPort: null,
        lif: null,        
        lastEquipment: null,
        lastEquipmentIP: null,        
        note: null,
      },
      formRules: {},
    };
  },
  computed: {
    isEditMode() {
      return (
        this.selectedItem && this.selectedItem.id && this.selectedItem.id > 0
      );
    },
  },
  watch: {
    selectedItem: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.formData = { ...newVal };
          this.formData.contractDate = this.convertDate(newVal.contractDate);
        } else {
          this.resetFormData();
        }
      },
    },
  },
  methods: {
    handleFormSubmit() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        this.$emit("formSubmit", this.formData);
      }
    },
    resetFormData() {
      this.formData = {
        company: null,
        junction:null,
        brxOrAik: null,
        brxOrAikId: null,
        decree: null,
        contract: null,
        contractDate: null,
        serviceType: null,
        speed: null,
        vlan: null,
        sideOrAddress: null,
        uplinkPort: null,
        uplinkSide: null,
        lastPort: null,
        lif: null,        
        lastEquipment: null,
        lastEquipmentIP: null,        
        note: null,
      };
      this.$refs.form && this.$refs.form.resetValidation();
    },
    convertDate(date) {
      if (date != null) return this.$moment(date).format("YYYY-MM-DD");
      else return null;
    },
  },
};
</script>
